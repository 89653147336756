import styles from "./Doctor.module.scss";
import DoctorPNG from "../../../assets/img/dr-george-pashias-in-office.jpeg";
import DentistSVG from "../../../assets/icons/dentist.svg";

export const doctorElementID = "doctor";

const Doctor: React.FC = () => {
  return (
    <section id={doctorElementID} className={styles.container}>
      <div className={styles.graphicText}>
        <h1>Dr. George Pashias</h1>

        <p>
          Dr. George Pashias graduated the Dental school of Athens university in
          1990 and running his own dental clinic in Limassol ever since.
        </p>

        <p>
          In 1992 completed the post-graduate courses in dental implants in
          Germany. More than 5000 implants were placed since 1992 giving him
          the experience of operating any case of dental implants.
        </p>

        <p className="bold" style={{ marginTop: 60 }}>
          “We always place our patients at the center of our attention, by
          concentrating on providing a painless procedure and at the same time
          creating beautiful aesthetics with the aid of the latest technologies”
        </p>
      </div>

      <div className={styles.graphic}>
        <div style={{ position: "relative" }}>
          <img alt="Doctor George Pashias" src={DoctorPNG} className={styles.graphicImage} />

          <div className={styles.iconCircle}>
            <img alt="Dentist Icon" src={DentistSVG} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Doctor;
