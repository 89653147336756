import { NavLink } from "react-router-dom";
import styles from "./Nav.module.scss";

interface IProps {
  onChange: () => void;
}

const Nav: React.FC<IProps> = (props) => {
  return (
    <ul className={styles.ulNav}>
      <li>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          onClick={props.onChange}
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/treatments"
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          onClick={props.onChange}
        >
          Treatments
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/clinic"
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          onClick={props.onChange}
        >
          The Clinic
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/team"
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          onClick={props.onChange}
        >
          Team
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          onClick={props.onChange}
        >
          Contact Us
        </NavLink>
      </li>
    </ul>
  );
};

export default Nav;
