import { Fade, Grid, Typography } from "@mui/material";
import teamMembers from "../../services/const/team";
import TeamMemberView from "./TeamMemberView";
import { useState } from "react";
import { MousePointer } from "react-feather";
import styles from "./TeamMembers.module.scss";

interface TeamMembersProps {
  containerClassName?: string;
}

export const teamMembersElementID = "team-members";

export const TeamMembers: React.FC<TeamMembersProps> = (props) => {
  const [mouseEntered, setMouseEntered] = useState(false);

  return (
    <>
      <Fade in={!mouseEntered} style={{ alignSelf: "center" }}>
        <Typography className={styles.HelperText}>
          <MousePointer /> Hover over to read more
        </Typography>
      </Fade>

      <Grid
        id={teamMembersElementID}
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        spacing={2}
      >
        {teamMembers.map((member, index) => (
          <Grid key={member.name + "_" + index} item>
            <TeamMemberView
              data={member}
              onMouseEnter={() => {
                setMouseEntered(true);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
