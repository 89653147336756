import PageHeader from "../../components/PageHeader";
import {
  chairTVImageURL,
  clinicHeadImageURL,
  detailsImageURL,
  receptionImageURL,
  receptionSittingAreaImageURL,
  roomTechImageURL,
} from "../../services/const/images/clinic";

import styles from "./TheClinic.module.scss";
import ImageWithCaption from "../../components/ImageWithCaption";
import TechGallery from "../../components/home/TechGallery";
import { useEffect } from "react";

const TheClinic: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeader>The Clinic</PageHeader>

      <iframe
        className={styles.videoIFrame}
        src="https://www.youtube.com/embed/lv7dPGPPFPc?si=_4nR8iCk7c9fQur0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <br />

      <ImageWithCaption
        title="The Design"
        description="Crafting comfort, teeth and style: A look at our clinic's contemporary interior design"
        alt="front view from inside dental clinic"
        src={clinicHeadImageURL}
        direction="row"
        fullWidth
      />

      <ImageWithCaption
        title="Reception"
        description="Elegance welcomes you. Step inside our luxurious reception area, and our team will take care of the rest"
        alt="reception of dental clinic"
        src={receptionImageURL}
        direction="row-reverse"
        fullWidth
      />

      <ImageWithCaption
        title="Waiting Area"
        description="Clinical comfort. Experience serenity in our thoughtfully designed waiting area. Feel free to enjoy a cup of coffee, tea or sparkling water while you wait for your appointment"
        alt="sitting area in reception of dental clinic"
        src={receptionSittingAreaImageURL}
        direction="row"
        fullWidth
      />

      <ImageWithCaption
        title="Technology"
        description="The rooms are equipped with state of the art and up-to-date technology to ensure the best results"
        alt="room of dental clinic with machines and resting chairs"
        src={roomTechImageURL}
        direction="row-reverse"
        fullWidth
      />

      <TechGallery />

      <ImageWithCaption
        direction="row"
        title="Entertainment"
        description="Enjoy a distraction with our overhead TV screens. Continue the show you're currently watching or choose something from the large library available. No headphones? No problem. We have you covered with our wireless headphones. For our little friends, we have a selection of cartoons and kids movies to choose from"
        alt="room of dental clinic with chair and TV above it for patients to watch entertainment during procedures"
        src={chairTVImageURL}
      />

      <ImageWithCaption
        direction="row-reverse"
        title="Details"
        description="It is the little things that make a difference. From the moment you step inside our clinic, you will notice the attention to detail, not only in our work but also our workspace"
        alt="design details of the clinic, of a big flower design prop in the hallway"
        src={detailsImageURL}
      />
    </>
  );
};

export default TheClinic;
