const DigitalDentistryAbout: React.FC = () => {
  return (
    <>
      <h2>CEREC - a procedure with a long tradition</h2>

      <p>
        The CEREC procedure was developed at Zurinch University and has been
        sucessfully developed in dental practices since 1985 - i.e. for more
        than 35 years.
      </p>

      <p>
        Worldwide more than 25000 practices in over 50 countries work with the
        CEREC system.
      </p>

      <p>
        Since 1985 over 8 million patients have been treated using the CEREC
        procedure. More than 20 million ceramic restorations have been created
        and placed.
      </p>

      <p>
        Long-term clinical studies demonstrate that - in terms of durability -
        CEREC restorations are at least equivalent to gold restorations.
      </p>

      <p>
        Depending on the individual case, CEREC fillings can achieve even better
        results than gold fillings.
      </p>

      <h2>The CEREC treatment procedure means:</h2>

      <ul>
        <li>
          <b>No conventional impressions.</b> The CEREC 3D measuring camera
          scans the prepared tooth in just a few seconds. This eliminates the
          need for unpleasant silicone impressions.
        </li>

        <li>
          <b>No temporaries.</b> CEREC restorations are milled out of a solid
          ceramic block in just a few minutes. They are then ready to be placed
          immediately. This eliminates the need for temporary fillings and
          crowns. It also means that you need only one appointment. And you
          don’t have to wait for days on end until the ceramic filling is
          finished.
        </li>

        <li>
          <b>No long waiting periods.</b> Because CEREC restorations can be
          placed immediately, you need only one appointment. And you benefit
          from tooth restorations which have demonstrated their durability time
          and time again.
        </li>
      </ul>

      <h2>The CEREC procedure step by step</h2>

      <ol>
        <li>Before treatment begins we inform you about benefits of CEREC.</li>
        <li>
          After removing the decayed tissue or the defective amalgam filling we
          create a 3D image of the tooth with the aid of the CEREC camera.
        </li>
        <li>
          The restoration is designed on the monitor of the CEREC acquisition
          unit.
        </li>
        <li>
          The CEREC milling unit matches the restoration out of a ceramic block.
        </li>
        <li>The milled restoration is bonded directly to your tooth.</li>
      </ol>

      <h2>Ceramic fillings with CEREC</h2>

      <ul>
        <li>
          Do you want highly aesthetic, tooth-coloured fillings-created and
          placed during a single dental appointment?
        </li>
        <li>Would you prefer not to have an impression and temporary?</li>
        <li>Do you prefer metal-free, biocompatible materials?</li>
      </ul>

      <p>
        All this is now possible. Thanks to CEREC. And these ceramic
        restorations can be created and placed during a single appointment.
      </p>

      <p>
        CEREC is a sophisticated CAD/CAM system for the production of
        all-ceramic inlays, onlays, partial crowns, veneers and crowns for
        anterior and posterior teeth.
      </p>

      <h2>All-ceramic restorations</h2>

      <ul>
        <li>one visit</li>
        <li>no impressions</li>
        <li>no temporaries</li>
        <li>metal-free</li>
        <li>highly aesthetic</li>
        <li>long lasting</li>
      </ul>
      <p>Modern CAD/CAM technology makes it possible.</p>
    </>
  );
};

export default DigitalDentistryAbout;
