import { Facebook } from "react-feather";
import { Instagram } from "react-feather";
import styles from "./SocialMedia.module.scss";
import { FACEBOOK_LINK, INSTAGRAM_LINK } from "../../services/const/links";

const SocialMedia: React.FC = () => {
  return (
    <div className={styles.container}>
      <a
        className={styles.iconCircle}
        href={FACEBOOK_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </a>

      <a
        className={styles.iconCircle}
        href={INSTAGRAM_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
      </a>
    </div>
  );
};

export default SocialMedia;
