export const clinicHeadImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Freception-1.webp?alt=media&token=0d9cb2c2-9ad7-4372-9efd-42ab8d080a11";

export const receptionImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Freception-2.webp?alt=media&token=f81aa71e-9043-4375-894d-f9a41c5845e9";

export const receptionSittingAreaImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Freception-6.webp?alt=media&token=112bb591-1a9f-4ace-b67f-4f3bbf87be9d";

export const roomTechImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1.webp?alt=media&token=5fa3508b-3854-43bb-8f5c-43a3247ae331";

export const room3ChairImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Froom-3-chair.webp?alt=media&token=72e5ec28-1904-4909-bc67-5e5663d31ffb";

export const chairTVImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1-chair-tv.webp?alt=media&token=7e199c6a-7deb-4ff1-b5fa-b5b8c070d5d8";

export const detailsImageURL =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fhallway-2.webp?alt=media&token=c37b25c8-fb85-4112-ac4a-a114ad8873bc";

export const outside =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Foutside-2.webp?alt=media&token=aa4cf042-64ff-43dd-966a-7d5316d46dcd";

export const wc =
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fwc-3.webp?alt=media&token=ca9a89e8-a1af-4cd2-8651-5210b2c85b57";
