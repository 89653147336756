import { Service } from "../../../../types";
import styles from "./ServiceCard.module.scss";

interface IProps {
  service: Service;
  selected?: boolean;
  onClick?: () => void;
  containerStyle?: React.CSSProperties;
  size?: "small" | "large";
}

const ServiceCard: React.FC<IProps> = (props) => {
  const { service, selected, onClick, containerStyle, size = "large" } = props;

  return (
    <div
      className={`${styles.card} ${
        size === "small" ? styles.small : styles.large
      }
      ${selected ? styles.selected : ""}`}
      style={containerStyle}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <div className={styles.iconCircle} />

          <img
            alt="icon related to treatment service"
            className={styles.icon}
            src={service.src}
          />
        </div>
      </div>

      <h2>{service.title.replace(" ", "\n")}</h2>
    </div>
  );
};

export default ServiceCard;
