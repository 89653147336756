const FACEBOOK_LINK = "https://www.facebook.com/PashiasClinic";
const INSTAGRAM_LINK = "https://www.instagram.com/pashiasdental/";
const GOOGLE_MAPS_LINK = "https://goo.gl/maps/7sEv6SHsH6TJQbcE9";
const PHONE_NUMBER_STRING = "+35725346060";
const EMERGENCY_PHONE_NUMBER_STRING = "+35799638968"

const LOCATION = {
  lat: 34.702013,
  lng: 33.104635,
  address: "127 Vasileos Georgiou, 4048, Limassol, Cyprus",
};

export {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  GOOGLE_MAPS_LINK,
  PHONE_NUMBER_STRING,
  EMERGENCY_PHONE_NUMBER_STRING,
  LOCATION,
};
