import { Fade } from "@mui/material";
import { ReactElement, useState } from "react";

interface ImageProps {
  alt: string;
  src: string;
  lazy?: boolean;
  style?: React.CSSProperties;
  className?: string;
  fade?: boolean;
}

export const CondFadeWrapper: React.FC<{
  children: ReactElement<any, any>;
  fade: boolean;
  visible: boolean;
}> = ({ children, fade, visible }) => {
  if (fade) {
    return <Fade in={visible}>{children}</Fade>;
  }

  return <>{children}</>;
};

const Image: React.FC<ImageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <CondFadeWrapper fade={props.fade} visible={loaded}>
      <img
        alt={props.alt}
        src={props.src}
        className={props.className}
        style={props.style}
        onLoad={() => {
          setLoaded(true);
        }}
        loading={props.lazy ? "lazy" : "eager"}
      />
    </CondFadeWrapper>
  );
};

export default Image;
