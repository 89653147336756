import { Button, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import Doctor from "../../components/home/Doctor";
import Hero from "../../components/home/Hero";
import InfoTable from "../../components/home/InfoTable";
import Services from "../../components/home/Services";
import TechGallery from "../../components/home/TechGallery";
import Testimonials from "../../components/home/Testimonials";
import { GOOGLE_MAPS_LINK } from "../../services/const/links";
import styles from "./Home.module.scss";
import TheTeam from "../../components/team/TheTeam";
import ImageWithCaption from "../../components/ImageWithCaption";
import { wc } from "../../services/const/images/clinic";
import { ArrowRight, MapPin, PhoneCall } from "react-feather";

const Home: React.FC = () => {
  return (
    <>
      <Hero />

      <section className={styles.parallax1 + " inverted"}>
        <h1>We moved to a new location!</h1>
        <p>127 Vasileos Georgiou, Germasogeia, 4048, Limassol</p>

        <div className={styles.parallax1Buttons}>
          <Button
            variant="outlined"
            color="info"
            startIcon={<MapPin />}
            onClick={() => {
              window.open(GOOGLE_MAPS_LINK, "_blank");
            }}
          >
            Get Directions
          </Button>

          <Link to="/contact">
            <Button startIcon={<PhoneCall />} variant="outlined" color="info">
              Contact Us
            </Button>
          </Link>
        </div>

        {/* <iframe
          className={styles.videoIFrame}
          src="https://www.youtube.com/embed/lv7dPGPPFPc?si=FS_JeheamsyWJuza&amp;controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}
      </section>

      <Services />

      <TechGallery />

      <InfoTable />

      <Doctor />

      <Testimonials />

      <TheTeam type="navigate" />

      <ImageWithCaption
        alt="inside the clinic showing nice design of accessible toilets, disabled friendly"
        src={wc}
        direction="row-reverse"
        title="State of the art"
        titleSize="h5"
        description="Take a look at our clinic's facilities and design"
      >
        <MuiLink
          href="/clinic"
          component={Button}
          endIcon={<ArrowRight />}
          sx={{ margin: 2 }}
        >
          See the clinic
        </MuiLink>
      </ImageWithCaption>
    </>
  );
};

export default Home;
