import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layouts/main";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { createTheme, ThemeProvider } from "@mui/material";
import Treatments from "./pages/Treatments";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import TheClinic from "./pages/TheClinic";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#496157",
      },
      info: {
        main: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: `"Poppins", sans-serif`,
      fontSize: 16,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
            textTransform: "none",
            fontWeight: 500,
          },
          text: {},
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="treatments" element={<Treatments />} />
            <Route path="clinic" element={<TheClinic />} />
            <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
