import styles from "./Footer.module.scss";
import LogoTextSVG from "../../../assets/img/logo-text.svg";
import SocialMedia from "../../../components/SocialMedia";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className={styles.Footer}>
      <img alt="logo in text" src={LogoTextSVG} />
      <div className={styles.footerContainer}>
        <div className={styles.left}>
          <p>
            All you need for dental care or cosmetic, in Limassol by the sea
          </p>

          <div style={{ marginTop: 40 }}>
            <SocialMedia />
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div className={styles.right}>
          <div>
            <div style={{ flex: 1 }}>
              <Link to="/">Home</Link>
            </div>

            <div>
              <a href="tel://+35725346060">+357 25346060</a>
            </div>
          </div>

          <div>
            <div style={{ flex: 1 }}>
              <Link to="/treatments">Treatments</Link>
            </div>

            <div>
              <a href="mailto://pashias@cytanet.com.cy">
                pashias@cytanet.com.cy
              </a>
            </div>
          </div>

          <div>
            <div style={{ flex: 1 }}>
              <Link to="/clinic">The Clinic</Link>
            </div>
          </div>
          {/* 
          <div>
            <div>
              <Link to="/gallery">Gallery</Link>
            </div>
          </div> */}

          <div>
            <div style={{ flex: 1 }}>
              <Link to="/team">Team</Link>
            </div>
          </div>

          <div>
            <div style={{ flex: 1 }}>
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="muted" style={{ textAlign: "center", marginTop: 20 }}>
        Pashias Delc © {new Date().getFullYear()}
        <br />
        <span style={{ fontSize: "0.7em" }}>
          Made with ❤️ by{" "}
          <a href="https://tolypash.com" target="_blank" rel="noreferrer">
            Anatoly Pashias
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
