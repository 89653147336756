import testimonials from "../../../services/const/testimonials";
import TestimonialCard from "./TestimonialCard";
import styles from "./Testimonials.module.scss";

import Dots1SVG from "../../../assets/img/dots-1.svg";
import Dots2SVG from "../../../assets/img/dots-2.svg";

const Testimonials: React.FC = () => {
  return (
    <section className={styles.container}>
      {testimonials.slice(0, 3).map((testimonial, index) => (
        <TestimonialCard key={"testimonial" + index} data={testimonial} />
      ))}

      <img alt="dots" src={Dots1SVG} className={styles.dots1} />
      <img alt="dots" src={Dots2SVG} className={styles.dots2} />
    </section>
  );
};

export default Testimonials;
