import { Box, Typography } from "@mui/material";
import Image from "../Image";

import styles from "./ImageWithCaption.module.scss";
import { PropsWithChildren } from "react";

interface ImageWithCaptionProps extends PropsWithChildren {
  direction: "row" | "column" | "row-reverse" | "column-reverse";
  title?: string;
  titleSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  description?: string;
  descriptionSize?: "body1" | "body2";
  src: string;
  alt: string;
  className?: string;
  fullWidth?: boolean;
  imageBorderRadius?: number | string;
  containerStyle?: React.CSSProperties;
}

const ImageWithCaption: React.FC<ImageWithCaptionProps> = (props) => {
  const { imageBorderRadius = 20 } = props;

  const imageStyle: React.CSSProperties = {}

  if (props.direction === "row") {
    imageStyle.borderTopLeftRadius = imageBorderRadius;
    imageStyle.borderBottomLeftRadius = imageBorderRadius;
  } else if (props.direction === "row-reverse") {
    imageStyle.borderTopRightRadius = imageBorderRadius;
    imageStyle.borderBottomRightRadius = imageBorderRadius;
  } else {
    imageStyle.borderRadius = imageBorderRadius;
  }

  return (
    <Box
      sx={{
        width: props.fullWidth ? "100%" : "auto",
        flexDirection: props.direction,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 2,
        marginBottom: 2,
        ...props.containerStyle,
      }}
      className={`${styles.Container} ${props.className || ""}`}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: 5,
        }}
      >
        {props.title ? (
          <Typography variant={props.titleSize || "h3"} sx={{ maxWidth: 400 }}>
            {props.title}
          </Typography>
        ) : null}

        {props.description ? (
          <Typography variant={props.descriptionSize || "body2"} sx={{ maxWidth: 400 }}>
            {props.description}
          </Typography>
        ) : null}

        {props.children}
      </Box>

      <Image
        fade
        alt={props.alt}
        src={props.src}
        className={styles.Image}
        style={imageStyle}
      />
    </Box>
  );
};

export default ImageWithCaption;
