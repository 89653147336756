import { Paper, Typography } from "@mui/material";
import { TeamMember } from "../../../types";
import Image from "../../Image";
import styles from "./TeamMemberView.module.scss";
import { useEffect, useRef, useState } from "react";

interface TeamMemberProps {
  data: TeamMember;
  onMouseEnter?: () => void;
}

const TeamMemberView: React.FC<TeamMemberProps> = (props) => {
  const { data } = props;

  const [active, setActive] = useState(false);

  const descriptionContainerRef = useRef<HTMLDivElement>(null);
  const [descriptionContainerHeight, setDescriptionContainerHeight] =
    useState<number>();

  useEffect(() => {
    setDescriptionContainerHeight(
      descriptionContainerRef.current?.clientHeight
    );
  }, []);

  return (
    <div
      className={styles.Container}
      onMouseEnter={() => {
        if (!data.description) return;

        setActive(true);
        props.onMouseEnter?.();
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <Image
        lazy
        fade
        alt={data.title + " " + data.name}
        src={data.imageURL}
        className={styles.Image}
        style={{
          aspectRatio: "2 / 3"
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: active ? 20 : (descriptionContainerHeight ? descriptionContainerHeight - 10 : 0) * -1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s all",
        }}
      >
        <Paper
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            marginLeft: 5,
            marginRight: 5,
            maxWidth: "80%",
            boxSizing: "border-box",
            animation: "0.5s all",
            marginBottom: 2,
          }}
        >
          <Typography fontWeight="bold" textAlign="center">
            {data.title ? data.title + " " : ""}
            {data.name}
          </Typography>
        </Paper>

        {data.description ? <Paper
          ref={descriptionContainerRef}
          className="inverted"
          sx={{
            maxWidth: "80%",
            width: "100%",
            textAlign: "center",
            padding: 2,
            boxSizing: "border-box",
          }}
        >
          <Typography variant="caption">{data.description}</Typography>
        </Paper> : null}
      </div>
    </div>
  );
};

export default TeamMemberView;
