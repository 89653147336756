import { Testimonial } from "../../types";

const testimonials: Testimonial[] = [
  {
    name: "Manfred Hermann",
    date: "October 2014",
    text: "It's 5 stars plus. I am and all members of our family are for many years patients at Pashias Dental clinic. Even though we live in Russia (Moscow) we will always come to Dr. Pashias clinic where we enjoy a fully provided utmost care within a highly professional atmosphere. Without hesitation we can say: it's simply the best dental care. Thank you Dr. Pashias.",
  },
  {
    name: "Nadya Kubovski",
    date: "December 2020",
    text: "Very professional clinic, I had extensive treatments and can highly recommend Dr. Pashias",
  },
  {
    name: "Theophrastos Mantadelis",
    date: "May 2022",
    text: `They removed my tooth! They were excellent. This was my third extraction and was much less scary than the two previous once.
    The doctor was very precise, and spared me from any unnecessary drama and fear.
    Thank you doctor ☺️.
    Also a great thanks to the support personal all of them where very careful and helpful.
    Thank you all!`,
  },
  {
    name: "Jacqueline",
    date: "November 2015",
    text: `After so many years of being afraid of the dentist and then I met Dr George Pashias.I was very interested in having the Cerec Ceramic Crowns as my own teeth would never get any whiter due to the color of the enamel of my teeth.
        I was very nervous as my teeth were very sensitive and hated drills. After the consultation with Dr George Pashias I felt very comfortable and decided to go ahead. The best thing ever with this treatment was that it can be over in a day or two from start to finish. Didn’t have to wait for anything to be sent away everything was done on the premises.
        I had eight teeth were repaired on Monday and eight on Tuesday but the most incredible thing was I never felt a thing…..and from that day to this I haven’t had any problems and smile all the time.
        I would just like to say a big thank you to Dr Pashias and his team for all the support you gave me. I shall definetely recommend you.`,
  },
];

export default testimonials;
