import { Typography } from "@mui/material"
import techImages from "../../../services/const/images/tech"
import HorizontalGallery from "../../HorizontalGallery"
import styles from "./TechGallery.module.scss"

const TechGallery: React.FC = () => {
    return <section className={styles.container}>
        <div className={"inverted " + styles.topBar}>
            <Typography variant="h5" fontWeight="bold">We Practise Only Advanced Dental Technologies</Typography>
        </div>

        <HorizontalGallery images={techImages} />
    </section>
}

export default TechGallery
