import styles from "./Team.module.scss";
import PageHeader from "../../components/PageHeader";
import { TeamMembers } from "../../components/TeamMembers";
import TheTeam from "../../components/team/TheTeam";
import { useEffect } from "react";

const Team: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeader>The Team</PageHeader>

      <TheTeam type="scroll" />

      <TeamMembers containerClassName={styles.TeamMembers} />
    </>
  );
};

export default Team;
