import { Button } from "@mui/material";
import { PhoneCall } from "react-feather";
import Card from "../../../components/Card";
import { PHONE_NUMBER_STRING } from "../../../services/const/links";

interface IProps {
  className?: string;
}

const CallCard: React.FC<IProps> = (props) => {
  return (
    <Card
      style={{
        justifyContent: "center",
        width: 350,
        paddingTop: 35,
        paddingBottom: 30,
      }}
      className={props.className}
    >
      <h2>Book appointment</h2>

      <a href={"tel://" + PHONE_NUMBER_STRING}>
        <Button
          variant="contained"
          style={{ marginTop: 16 }}
          startIcon={<PhoneCall />}
        >
          +357 25 346060
        </Button>
      </a>
    </Card>
  );
};

export default CallCard;
