import { Service } from "../../types";
import GeneralSVG from "../../assets/icons/services/general.svg";
import PreventativeSVG from "../../assets/icons/services/preventative.svg";
import CosmeticSVG from "../../assets/icons/services/cosmetic.svg";
import WhiteningSVG from "../../assets/icons/services/white.svg";
import DigitalSVG from "../../assets/icons/services/digital.svg";
import ImplantsSVG from "../../assets/icons/services/implants.svg";
import SurgerySVG from "../../assets/icons/services/surgery.svg";
import RadiographySVG from "../../assets/icons/services/radiography.svg";
import DigitalDentistryAbout from "../../components/treatments/digital";
import OralSurgeryAbout from "../../components/treatments/surgery";

const services: Service[] = [
  {
    title: "General Dentistry",
    src: GeneralSVG,
    description:
      "Our clinic offers all standard dental treatments, such as cleaning and polish, fillings, root-canal treatment, tooth removals and prosthetic restorations fixed or removable.",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F1c37c54f-889a-4903-8fe0-01fbde5d4b85.JPG?alt=media&token=58b01bba-f2b6-40d4-aa67-c1c1d65be25d",
    faq: [
      {
        question: "What type of toothbrush and toothpaste should I use?",
        answer:
          "It may seem like a small thing but the actual tools you use on a daily basis can have a big effect on your dental health. For instance, did you know that even though hard bristled toothbrushes are effective at removing plaque and stains, they can damage your teeth and gums if you're not careful? With so many kinds of toothbrushes available today: electric, hard and soft bristle patterns, and more, it's best to get a recommendation from your dentist for what's right for you. Make sure you're using the right product for your teeth by bringing up the topic during your appointment. The same goes for brand of toothpaste you use. If you have sensitive teeth, your dentist can recommend a good toothpaste to help. Or if you're looking to brighten your smile, your dentist can provide you with some safe options for that too.",
      },
      {
        question: "Should I use a mouthwash?",
        answer:
          "Although mouthwash does not replace good brushing and flossing habits, it can be beneficial for your dental health. Using mouthwash helps freshen your breath, control bacteria, and strengthen your teeth. However, it's very important for you to use the right type of mouthwash and you need to use it as directed. Talk to your dentist about whether or not mouthwash is right for you and if so, what kind you should be using. Your dentist can explain how you can benefit most from your mouthwash and give you instructions for use. Your dentist can also help you understand what potential negative side effects to be be aware of. For instance, mouth sores, root sensitivity, or numbness.",
      },
    ],
  },
  {
    title: "Preventative Dentistry",
    src: PreventativeSVG,
    description: `Is the practice of caring for your teeth to keep them healthy. This helps to avoid cavities, gum disease , enamel wear and more...\n
    There are many forms of preventative dentistry,such as:Daily brushing and annual dental clinics. This practices are designed to ensure that teeth are clean, strong and white. Children should be taught proper oral hygiene at an early age.\n
    Our concern is to educate our young patients in brushing,give fluoridation of the teeth and more important to familiarize with the dentist and make them understand how important is to have clean and healthy teeth.\n
    Preventative Dentistry continues in older ages in the form of teeth cleaning and checkup every 6 months.`,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F5f00528d-e2d5-46b4-be3a-d0308370cac5.JPG?alt=media&token=ea514290-9a15-47a6-a399-00f7a69d9912",
    faq: [
      {
        question: "When is a dental filling needed?",
        answer:
          "A dental filling restores and repairs the surface of a tooth that has been weakened by decay, fracture, or abnormal wear. If tooth decay or damage is not addressed in it's initial stages, it will just become worse and require additional complicated dental procedures and costs. Things like pain, discomfort and sensitivity are often early warning signs that you may need a filling. We will diagnose what is causing the issue during your examination and recommend and appropriate treatment to ease your discomfort.",
      },
      {
        question: "Do you need a root canal?",
        answer:
          "If detected early enough, tooth decay can be corrected with a small composite filling. However, when decay and infection spread beyond the outer layers of the teeth and invade the pulp and root, a more advanced endodontic solution, like a root canal may be required. At Pashias Dental Clinic, we offer endodontic treatments, including root canals, to treat serious tooth decay and infection.",
      },
    ],
  },
  {
    title: "Cosmetic Dentistry",
    src: CosmeticSVG,
    description: `Pashias Dental Clinic has the technology and the knowledge to help you solve any oral cosmetic problems like replacing old amalgam fillings with high quality composite resin ones or even better with ceramic inlays.\n
    We can give you an attractive smile done in one day, with the help of digital dentistry providing you ceramic crowns or veneers.`,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F50f8532c-03b7-48dd-9fe5-17cd64ec98d1.JPG?alt=media&token=ea4fce1f-9ac4-4a3a-adf3-5d7d9ce41e43",
    faq: [
      {
        question: "What does a a smile makeover typically include?",
        answer:
          "There are many cosmetic dentistry procedures that we can use, including teeth whitening, dental veneers, dental crowns and dental implants, among others, but this all depends on your unique needs. There are a few treatments we may use to achieve a healthy functional mouth and the smile of your dreams.",
      },
      {
        question: "What are dental crowns?",
        answer:
          "A dental crown is a tooth-shaped 'cap' that is placed over a tooth to cover it to restore its shape and size, strength, and improve its appearance. The crowns, when cemented into place, fully encase the entire visible portion of a tooth that lies at and above the gum line.",
      },
    ],
  },
  {
    title: "Teeth Whitening",
    src: WhiteningSVG,
    description: `A white smile is essential to us all. The use of tobacco, food and drink, the daily use of medication colouring our teeth and our smile ceases to be so attractive. The simplest way to recover a bright smile is teeth whitening. The whole process can be done in the office, at home or in a combination of both. The procedure is absolutely safe to the teeth and can achieve 2-3 tones whiter on the chromatic scale. The result can be kept longer if occasionally the patient receives a booster dose of whitening at home.`,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F49e6a590-6d9c-4c9b-895a-b5db6fe3016b.JPG?alt=media&token=6b2517ca-61c1-4be9-843c-0c74d5dcf2cb",
    faq: [
      {
        question: "Does teeth whitening work?",
        answer:
          "Our teeth whitening treatments are non-invasive and they really work! You'll absolutely love the results. Schedule your free consultation and learn more about our teeth whitening treatment options. We are always here to help.",
      },
    ],
  },
  {
    title: "Digital Dentistry",
    src: DigitalSVG,
    description: "",
    jsx: DigitalDentistryAbout,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F4b31c800-7e1c-4961-b15e-43d114c6d6b2.JPG?alt=media&token=81706acc-dced-42d3-ba2c-ad9920507a5a",
    faq: [],
  },
  {
    title: "Implants",
    src: ImplantsSVG,
    description: `Dental Implants have become a very good and proven solution to restore missing teeth, whether this is one tooth missing or an edentulous jaw.\n

    Dental Implants gave solutions to many patients that have to wear a full or partial denture, giving them the hope to smile with confidence by replacing removable restoration with fixed ceramic prosthesis.\n
    
    Treatment plants, including implants can receive any patient whose general health allow minor surgery.\n
    Age is not a limiting factor in the success of implants.`,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2Ffebbfa3e-77ce-4868-8c44-ce67a0833b94.JPG?alt=media&token=027f9c4c-c2b5-4a62-9ec9-9da2db93b3e8",
    faq: [
      {
        question: "What are dental implants?",
        answer:
          "Dental implants are replacement tooth roots. Implants provide a strong foudnation for fixed (permanent) or removable replacement teeth that are made to match your natural teeth.",
      },
      {
        question: "How successful are dental implants?",
        answer:
          "Success rates of dental implants vary, depending on where in the jaw the implants are placed but, in general, dental implants have a success rate of up to 98%. With proper care, implants can last a lifetime.",
      },
      {
        question: "Are you a candidate for dental implants?",
        answer:
          "You're an ideal candidate for a dental implant if: 1) you're in good general and oral health. 2) you have adequate bone in your jaw to support the implant. 3) you have healthy gum tissues that are free of periodontal disease.",
      },
      {
        question: "How painful are dental implants?",
        answer:
          "Most people who have received dental implants say that there is very little discomfort involved in the procedure. Local anesthesia can be used during the procedure, and most patients report that implants involve less pain than a tooth extraction. After the dental implant, mild soreness can be treated with over-the-counter pain medications, such as Tylenol or Motrin.",
      },
      {
        question: "How do I care for my dental implants?",
        answer:
          "Dental implants require the same care as real teeth, including brushing, flossing, rinsing with an antibacterial mouthwash, and regular dental check-ups.",
      },
    ],
  },
  {
    title: "Oral Surgery",
    src: SurgerySVG,
    description: "",
    jsx: OralSurgeryAbout,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F4baaa76d-b230-482e-b271-1f4712229229.JPG?alt=media&token=235676d6-776f-40ec-89a7-bb957c64eec3",
    faq: [],
  },
  {
    title: "Digital Radiography",
    src: RadiographySVG,
    description:
      "Latest digital imaging technology panoramic and intraoral is available in clinic. A necessary tool for diagnosis.",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftreatments%2F0dcae550-e217-435d-abe3-80c176e2f6ab.JPG?alt=media&token=ecc3765d-8d83-4e32-bb3e-7ac12f415a69",
    faq: [],
  },
];

export default services;
