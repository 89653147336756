import { Button } from "@mui/material";
import MeetCard from "./MeetCard";
import Image from "../../Image";
import styles from "./Hero.module.scss";
import { useNavigate } from "react-router-dom";
import ByDrPNG from "../../../assets/img/by-dr-pashias-black.png";
import { heroImageURL } from "../../../services/const/images";

const Hero: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.container}>
      <div className={styles.graphicText}>
        <div className={styles.headlineContainer}>
          <h1 style={{ fontWeight: 500 }}>Welcome to Dental Clinic</h1>
          <Image
            fade
            alt="by doctor pashias"
            src={ByDrPNG}
            className={styles.headlineTextImage}
            style={{
              aspectRatio: "2698 / 645",
            }}
          />
        </div>
        <p>
          Our mission is to provide first-class dental healthcare for the local
          community in a safe, comfortable, and welcoming environment.
        </p>

        <p>
          We check for your current dental situation and decide your treatment.
          Our exceptional and experienced staff is dedicated to improving our
          patients’ dental health and enhancing smiles.
        </p>

        <p>
          The Pashias Dental Clinic was founded in 1990, Limassol, Cyprus. The
          clinic specializes in dental implants, teeth whitening and cosmetic
          dentistry.
        </p>

        <Button
          variant="contained"
          style={{ marginTop: 30 }}
          onClick={() => navigate("/contact")}
        >
          Book Appointment
        </Button>
      </div>

      <div className={styles.graphic}>
        <div className={styles.graphicCircle} />

        <Image
          fade
          alt="room of the clinic"
          src={heroImageURL}
          className={styles.graphicImage}
          style={{
            aspectRatio: " 1 / 1",
          }}
        />

        <MeetCard className={styles.card} />
      </div>
    </section>
  );
};

export default Hero;
