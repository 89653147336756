import { PropsWithChildren } from "react";
import styles from "./Card.module.scss";

interface IProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

const Card: React.FC<IProps> = (props) => {
  return (
    <div
      className={`${styles.Card} ${props.className ? props.className : ""}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default Card;
