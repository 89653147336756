import { Avatar } from "@mui/material";
import styles from "./AvatarRow.module.scss";

interface IProps {
  name: string;
  aboutText?: string;
  src: string;
  containerStyle?: React.CSSProperties;
  onClick?: () => void;
}

const AvatarRow: React.FC<IProps> = (props) => {
  const [firstName, lastName] = props.name.split(" ");

  return (
    <div
      className={styles.container}
      style={props.containerStyle}
      onClick={props.onClick}
    >
      <Avatar
        src={props.src}
        sx={{ width: 52, height: 52, bgColor: "lightgray" }}
      >
        {!props.src
          ? `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ""}`
          : undefined}
      </Avatar>

      <div style={{ marginLeft: 12, textAlign: "left" }}>
        <h4>{props.name}</h4>
        {props.aboutText ? (
          <div className="muted">{props.aboutText}</div>
        ) : null}
      </div>
    </div>
  );
};

export default AvatarRow;
