import BrookenToothPNG from "../../assets/img/broken-tooth.png";

const NotFound: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="broken tooth"
        src={BrookenToothPNG}
        style={{
          width: 100,
          paddingTop: 100,
        }}
      />

      <p style={{paddingTop: 25}}>Sorry, page not found</p>
    </div>
  );
};

export default NotFound;
