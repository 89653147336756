import GoogleMapReact from "google-map-react";
import { useState } from "react";
import MapPinSVG from "../../assets/icons/map-pin.svg";
import { LOCATION } from "../../services/const/links";
import styles from "./Map.module.scss";

const LocationPin: React.FC<{ lat: number; lng: number; opacity: number }> = ({
  opacity,
}) => {
  return (
    <div
      className={styles.pinIconContainer}
      style={{
        opacity,
      }}
    >
      <div className={styles.pinIconCircle1} />
      <div className={styles.pinIconCircle2} />
      <img alt="map pin" src={MapPinSVG} />
    </div>
  );
};

const Map: React.FC = () => {
  const [opacity, setOpacity] = useState(1);

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB0GPW90Z06gzzDcZy1YsWqtlxGHcX6HKU" }}
        defaultCenter={{ lat: LOCATION.lat, lng: LOCATION.lng }}
        defaultZoom={18}
        onDrag={() => {
          setOpacity(0);

          setTimeout(() => setOpacity(1), 750);
        }}
        onDragEnd={() => {
          setOpacity(1);
        }}
        options={{
          maxZoom: 23,
          minZoom: 17,
        }}
      >
        <LocationPin lat={LOCATION.lat} lng={LOCATION.lng} opacity={opacity} />
      </GoogleMapReact>
    </>
  );
};

export default Map;
