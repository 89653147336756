import { Testimonial } from "../../../../types";
import AvatarRow from "../../../AvatarRow";
import Card from "../../../Card";
import styles from "./Testimonial.module.scss";

interface IProps {
  data: Testimonial;
}

const TestimonialCard: React.FC<IProps> = (props) => {
  const { name, date, text, photoURL } = props.data;
  return (
    <Card className={styles.container}>
      <p>{text}</p>

      <AvatarRow name={name} src={photoURL} aboutText={date} />
    </Card>
  );
};

export default TestimonialCard;
