import { Outlet, useNavigate } from "react-router-dom";
import LogoTextSVG from "../../assets/img/logo-text.svg";
import styles from "./main.module.scss";
import Hamburger from "hamburger-react";
import { useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const Layout: React.FC = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.layout}>
      <header
        className={`${styles.header} ${isMenuOpen ? styles.hideShadow : ""}`}
      >
        <img
          alt="logo"
          src={LogoTextSVG}
          className={styles.logo}
          onClick={() => {
            navigate("/");
          }}
        />

        <div className={styles.hamburger}>
          <Hamburger
            color="#121212"
            rounded
            label="Show navigation menu"
            toggled={isMenuOpen}
            toggle={setIsMenuOpen}
          />
        </div>

        <nav className={styles.nav}>
          <Nav onChange={() => {}} />
        </nav>
      </header>

      <nav
        className={`${styles.hamburgerNav} ${isMenuOpen ? styles.open : ""}`}
      >
        <Nav onChange={() => setIsMenuOpen(false)} />
      </nav>

      <main className={styles.main}>
        <Outlet />
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
