import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface PageHeaderProps extends PropsWithChildren {
  inverted?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { children, inverted = true } = props;

  return (
    <Box
      className={inverted ? "inverted" : undefined}
      sx={{
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
        {children}
      </Typography>
    </Box>
  );
};

export default PageHeader;
