const OralSurgeryAbout: React.FC = () => {
  return (
    <>
      <p>In our clinic surgery procedures can be performed such as:</p>
      <ul>
        <li>Apex Ectomy</li>

        <li>Simple and complicated extractions of impacted wisdom teeth</li>

        <li>Removing of cyst</li>
      </ul>
    </>
  );
};

export default OralSurgeryAbout;
