import { useState } from "react";
import { useRef } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import styles from "./HorizontalGallery.module.scss";
import Image from "../Image";

interface IProps {
  height?: number;
  imageWidth?: number;
  images: string[];
}

const DEFAULT_HEIGHT = 400;
const DEFAULT_IMAGE_WIDTH = 310;

const HorizontalGallery: React.FC<IProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isCloseToStart, setIsCloseToStart] = useState(true);
  const [isCloseToEnd, setIsCloseToEnd] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const scroll = (amount: number) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollLeft + amount,
      });
    }
  };

  return (
    <div
      ref={containerRef}
      className={styles.container}
      style={{
        height: props.height || DEFAULT_HEIGHT,
      }}
      onScroll={(e) => {
        const { scrollLeft, scrollWidth, clientWidth } =
          e.target as HTMLDivElement;

        if (!scrolled) {
          setScrolled(true);
        }

        if (scrollWidth - (scrollLeft + clientWidth) < 100) {
          setIsCloseToEnd(true);
        } else {
          setIsCloseToEnd(false);
        }

        if (scrollLeft < 100) {
          setIsCloseToStart(true);
        } else {
          setIsCloseToStart(false);
        }
      }}
    >
      {props.images.map((imgUrl, index) => (
        <Image
          fade
          alt={"technology " + index}
          key={"horizontal_gallery_image_" + index}
          src={imgUrl}
          className={styles.image}
          style={{
            width: props.imageWidth || DEFAULT_IMAGE_WIDTH,
            minWidth: props.imageWidth || DEFAULT_IMAGE_WIDTH,
          }}
        />
      ))}

      <div
        className={`${styles.arrow} ${styles.left} ${
          isCloseToStart ? styles.hidden : ""
        }`}
        onClick={() => {
          scroll((props.imageWidth || DEFAULT_IMAGE_WIDTH) * -1);
        }}
      >
        <ChevronLeft />
      </div>

      <div
        className={`${styles.arrow} ${styles.right} ${
          isCloseToEnd ? styles.hidden : ""
        } ${!scrolled ? styles.bounce : ""}`}
        onClick={() => {
          scroll(props.imageWidth || DEFAULT_IMAGE_WIDTH);
        }}
      >
        <ChevronRight />
      </div>
    </div>
  );
};

export default HorizontalGallery;
