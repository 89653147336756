import { Button, Link, TextField } from "@mui/material";
import Card from "../../components/Card";
import Map from "../../components/Map";
import DirectionsSVG from "../../assets/icons/location.svg";
import { GOOGLE_MAPS_LINK } from "../../services/const/links";
import styles from "./Contact.module.scss";
import InfoTable from "../../components/home/InfoTable";
import CallCard from "./CallCard";
import { contactImageURL } from "../../services/const/images";
import PageHeader from "../../components/PageHeader";
import ImageWithCaption from "../../components/ImageWithCaption";
import { outside } from "../../services/const/images/clinic";
import { ArrowRight } from "react-feather";
import { useEffect } from "react";

const Contact: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <PageHeader>Contact</PageHeader>

      <section className={styles.map}>
        <div className={styles.mapContainer}>
          <Map />
        </div>

        <Card className={styles.mapCard}>
          <h2>Find us</h2>
          <hr style={{ marginTop: 6 }} />
          <p>
            127 Vasileos Georgiou, 4048
            <br /> Limassol, Cyprus
          </p>

          <Button
            variant="outlined"
            onClick={() => {
              window.open(GOOGLE_MAPS_LINK, "_blank");
            }}
          >
            Get Directions
          </Button>

          <div className={styles.directionsIcon}>
            <img alt="directions" src={DirectionsSVG} />
          </div>
        </Card>
      </section>

      <section className={styles.contact}>
        <div className={styles.contactLeft}>
          <h1>
            You have any questions?
            <br />
            Request a callback.
          </h1>

          <p>We will call you as soon as possible.</p>

          <form
            action="https://formbold.com/s/oP0ao"
            method="POST"
            className={styles.contactForm}
          >
            <TextField
              name="name"
              variant="standard"
              label="Name"
              required
              className={styles.input}
            />

            <TextField
              name="phone"
              variant="standard"
              label="Phone Number"
              required
              className={styles.input}
            />

            <TextField
              name="message"
              variant="standard"
              label="Message"
              className={styles.input}
            />

            <Button
              variant="contained"
              style={{
                marginTop: 30,
              }}
              type="submit"
            >
              Submit Request
            </Button>
          </form>
        </div>

        <div className={styles.contactRight}>
          <img
            alt="contact background"
            src={contactImageURL}
            className={styles.graphicImage}
          />

          <CallCard className={styles.contactCard} />
        </div>
      </section>

      <InfoTable />

      <ImageWithCaption
        containerStyle={{
          marginTop: 6,
        }}
        alt="outside view of the clinic, to help with finding it"
        src={outside}
        title="By the sea"
        description="Find us by the sea, in Limassol"
        direction="row-reverse"
        imageBorderRadius={100
        }
      >
        <Link
          href="/clinic"
          component={Button}
          endIcon={<ArrowRight />}
          sx={{ margin: 2 }}
        >
          See the clinic
        </Link>
      </ImageWithCaption>
    </>
  );
};

export default Contact;
